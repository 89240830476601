document.addEventListener('turbolinks:load', () => {
  const $versionSelector = document.querySelector('.docs.versions select');
  if (!$versionSelector) {
    return;
  }

  $versionSelector.addEventListener('change', () => {
    const newPath = window.location.pathname.replace(/\/(?:[^\/]*?)\d(?:[^\/]*?)(?:\/|$).*/, `/${$versionSelector.value}/`)
    Turbolinks.visit(newPath);
  });
});
